import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import BannerHeading from "../components/BannerHeading";
import CallToAction from "../components/CallToAction";
import Layout from "../components/Layout";
import PageBanner from "../components/PageBanner";
import Menu from "../components/PizzaMenus/Menu";
import MenuList from "../components/PizzaMenus/MenuList";
import { data } from "../data/index";

const Favorities = () => {
  return (
    <Layout title="Tasty Pizza – Favorites">
      <PageBanner
        title={data.favorites.pageHeading}
        image={data.favorites.image}
      />
      <BannerHeading
        title={data.favorites.pageSubHeading}
        description={data.favorites.description}
      />

      <div class="favorites-area">
        <div class="container">
          <div class="row">
            <div class="col-lg-7 col-md-7">
              <div class="favorites-item-warp">
                <MenuList menu={data.favorites.nachos} />
                <MenuList menu={data.favorites.favouritesSides} />
                <Menu menu={data.favorites.burgerMenu} />
                <MenuList menu={data.favorites.pitaWrap} />
                <Menu menu={data.favorites.poutine} />
                <MenuList menu={data.favorites.breadSticks} />
                <MenuList menu={data.favorites.pockets} />
                <MenuList menu={data.favorites.salads} />
                <MenuList menu={data.favorites.pasta} />
                <div class="row">
                  <div className="col-lg-6">
                    <MenuList menu={data.favorites.chickenWings} />
                  </div>
                  <div className="col-lg-6">
                    <MenuList
                      className="col-lg-6"
                      menu={data.favorites.chickenAndChips}
                    />
                  </div>
                </div>
                <MenuList menu={data.favorites.chickenFamilyPack} />
                <MenuList menu={data.favorites.chickenNuggets} />
                <MenuList menu={data.favorites.chickenFingers} />
              </div>
            </div>
            <div class="col-lg-5 col-md-5">
              <div class="favorite-right">
                <div class="favorite-thumb">
                  <StaticImage
                    src="https://res.cloudinary.com/dzoanfblg/image/upload/v1623728869/Tasty%20Pizza/360_F_184571580_XNGjXEeim7Qb0DeP8Di75JXxY056vgDJ_fvn7td.jpg"
                    alt="Tasty Pizzza - Nachos"
                  />
                </div>
                <div class="favorite-thumb">
                  <StaticImage
                    src="https://res.cloudinary.com/dzoanfblg/image/upload/v1623671942/Tasty%20Pizza/Club3_iokckb.jpg"
                    alt="Tasty Pizza – Subs"
                  />
                </div>
                <div class="favorite-thumb">
                  <CallToAction
                    title="We’re Easy to Find"
                    description="Look us up on our interactive map on the Contact Page"
                    linkText="Get Directions"
                    linkUrl="/contact"
                  />
                </div>
                <div class="favorite-thumb">
                  <StaticImage
                    src="https://res.cloudinary.com/dzoanfblg/image/upload/v1623671989/Tasty%20Pizza/Donair_Burger_wj9ja0.jpg"
                    alt="Tasty Pizza – Burger"
                  />
                </div>
                <div class="favorite-thumb">
                  <StaticImage
                    src="https://res.cloudinary.com/dzoanfblg/image/upload/v1623672162/Tasty%20Pizza/Poutine3_bh9lex.jpg"
                    alt="Tasty Pizza – Poutine"
                  />
                </div>
                <div class="favorite-thumb">
                  <StaticImage
                    src="https://res.cloudinary.com/dzoanfblg/image/upload/v1623672218/Tasty%20Pizza/L_G1_t6qdqd.jpg"
                    alt="Tasty Pizza – Pasta"
                  />
                </div>
                <div class="favorite-thumb">
                  <StaticImage
                    src="https://res.cloudinary.com/dzoanfblg/image/upload/v1623672309/Tasty%20Pizza/caesar_salad1_yy5dja.jpg"
                    alt="Tasty Pizza – Salad"
                  />
                </div>
                <div class="favorite-thumb">
                  <StaticImage
                    src="https://res.cloudinary.com/dzoanfblg/image/upload/v1623728739/Tasty%20Pizza/nuggets2_zoy7p3.jpg"
                    alt="Tasty Pizza – Chicken"
                  />
                </div>
                <div class="favorite-thumb">
                  <StaticImage
                    src="https://res.cloudinary.com/dzoanfblg/image/upload/v1623728747/Tasty%20Pizza/fingers1_owejla.jpg"
                    alt="Tasty Pizza – Chicken"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Favorities;
